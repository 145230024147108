import { notification } from '~/components/notification'

import { ONE_SECOND_IN_MS } from '~/scripts/utils'

/**
 * Order gateway
 *
 * Send email to server, got 200 OK and redirect to link address
 *
 * @param {Element} where
 */
export const orderGateway = (where) => {
	const blocks = where.querySelectorAll('[data-order-gateway]')
	Array.from(blocks).forEach(block => init(block))
}

/**
 * @param {Element} element
 */
const init = element => {
	const optionsAttribute = element.getAttribute('data-order-gateway')
	const { redirectURL, successText, failText } = JSON.parse(optionsAttribute)

	const trigger  = element.querySelector('[data-order-gateway-trigger]')
	const form  = element.querySelector('[data-order-gateway-form]')

	trigger.addEventListener('click', click => {
		click.preventDefault()
		trigger.hidden = true
		form.hidden = false
		console.log({ click, redirectURL })
	})

	form.addEventListener('submit', event => {
		submit(event, { form, redirectURL, successText, failText })
	})
}

/**
 * @param {HTMLFormElement} form
 * @param {Boolean} bool
 */
const formIsDisabled = (form, bool) => {
	Array.from(form.elements).forEach(control => control.disabled = bool)
}

/**
 * @param {Event} event
 * @param {{
 *     form: HTMLFormElement
 *     redirectURL: string
 *     successText: string
 *     failText: string
 * }} options
 */
const submit = (event, {
	form,
	redirectURL,
	successText,
	failText,
}) => {
	event.preventDefault()
	const formData = new FormData(form)
	formIsDisabled(form, true)
	console.log({
		event,
		redirectURL,
		action: form.action,
		entries: Object.fromEntries(formData.entries()),
	})
	fetch(form.action, {
		method: 'POST',
		body: formData,
	}).then(response => {
		console.log({ response })
		formIsDisabled(form, false)
		if (response.status === 200) {
			console.log('200 ok')
			notification(successText, {
				time: ONE_SECOND_IN_MS * 5,
			})
			setTimeout(() => {
				window.location = redirectURL
				console.log('redirect')
			}, ONE_SECOND_IN_MS * 3)
		}
		else {
			console.log('not ok')
			notification(failText, { type: 'negative' })
		}
	})
}
