import { ONE_SECOND_IN_MS, rootCustomProperty } from '~/scripts/utils'

const NOTIFICATION_ANIMATION_LENGTH = Number(rootCustomProperty('--js-notification-animation-length'))

/**
 * Create or return existing notifications container
 * @returns {Element}
 */
const container = () => {
	const attr = 'data-notifications'
	let container = document.body.querySelector(`[${attr}]`)
	if (!container) {
		container = document.createElement('aside')
		container.setAttribute('data-notifications', '')
		container.classList.add('notifications')
		document.body.appendChild(container)
	}
	return container
}

/**
 * @typedef NotificationOptions
 * @type {{
 *     type: 'positive'|'negative'
 *     time: milliseconds = 300
 * }}
 */

/**
 * Show notification
 *
 * @param {string} text
 * @param {NotificationOptions} options = {}
 */
export const notification = (text, options = {}) => {
	const instance = create(text, options)
	container().prepend(instance)
	return instance // to close from outside of the component
}

/**
 * @typedef {int} milliseconds
 */

/**
 * @typedef NotificationType
 * @type {({name: string, className: string})[]}
 */
const TYPES = [
	{
		name: 'positive',
		className: 'is-positive',
	},
	{
		name: 'negative',
		className: 'is-negative',
	},
]

/**
 * @param {string} wantType
 * @returns {string} type class name
 */
const typeClassName = wantType => {
	return TYPES.find(type => type.name === wantType).className
}

/**
 * @param {string} text
 * @param {NotificationOptions} options
 * @returns {Element} element
 */
const create = (text, { type, time }) => {
	type = type || 'positive'
	time = time || 8 * ONE_SECOND_IN_MS

	const notificationElement = document.createElement('article')
	notificationElement.innerHTML = text
	notificationElement.classList.add('notification')
	notificationElement.classList.add(typeClassName(type))
	notificationElement.style.setProperty('--timeout', `${time}ms`)

	notificationElement.appendChild(closeButton(notificationElement))

	const timeout = setTimeout(() => {
		close(notificationElement)
		setTimeout(() => {
			notificationElement.remove()
		}, NOTIFICATION_ANIMATION_LENGTH)
		clearTimeout(timeout)
	}, time)

	return notificationElement
}

/**
 * Close notification
 * @param {Element} notification
 */
export const close = notification => {
	notification.classList.add('is-hidden')
}

/**
 * Create close button
 * @param notificationElement
 * @returns {HTMLButtonElement}
 */
const closeButton = (notificationElement) => {
	const closeButton = document.createElement('button')
	closeButton.innerHTML = 'Закрыть'
	closeButton.addEventListener('click', event => close(notificationElement))
	closeButton.classList.add('notification-close')
	return closeButton
}
