import { lock, unlock } from 'tua-body-scroll-lock'
import 'tocca' // swipedown event
/**
 * Modal
 *
 * @param {Element} where
 */
export const modal = (where) => {
	const models = where.querySelectorAll('input[data-modal]')
	Array.from(models).forEach(model => {
		const name = model.getAttribute('data-modal')
		const pane = where.querySelector(`#${name} [data-modal-overflow]`)
		if (model.checked) {
			lock(pane)
		}
		model.addEventListener('change', event => {
			if (model.checked) {
				lock(pane)
			} else {
				unlock(pane)
			}
		})
		initCloseOnSwipeDown({ model, pane })
	})
}

const initCloseOnSwipeDown = ({ model, pane }) => {
	// time to detect that user stop scrolling
	const MS_BETWEEN_SCROLL_EVENTS = 300
	// if user tap for a long, its scroll. only short-time touchend is swipe
	const MS_AFTER_SCROLL_START_TO_SWIPE = 150

	let isScrolling = false
	let debounceTimeout
	pane.addEventListener('scroll', event => {
		clearTimeout(debounceTimeout)
		isScrolling = true
		debounceTimeout = setTimeout(() => {
			isScrolling = false
			clearTimeout(debounceTimeout)
		}, MS_BETWEEN_SCROLL_EVENTS)
	}, { passive: true })

	let start
	let end
	let diff
	pane.addEventListener('touchstart', event => {
		start = new Date()
	})
	pane.addEventListener('touchend', event => {
		end = new Date()
		diff = end - start
	})

	pane.addEventListener('swipedown', event => {
		const { pointerType } = event.originalEvent
		const isMousePointer = pointerType === 'mouse'
		const noTimeToSwipe = diff > MS_AFTER_SCROLL_START_TO_SWIPE
		if (
			pane.scrollTop > 0 || isScrolling || isMousePointer || noTimeToSwipe
		) return
		model.checked = false
		unlock(pane)
	}, { passive: true })
}
