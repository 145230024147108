import normifyListeners from 'normify-listeners'
import { modal } from '~/components/modal'
import { carousel } from '~/scripts/carousel'
import { orderGateway } from '~/components/order-gateway'

normifyListeners()

const rootElement = document.documentElement

document.addEventListener('DOMContentLoaded', ready => {
	modal(rootElement)
	carousel(rootElement)
	orderGateway(rootElement)
})
