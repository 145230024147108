import Siema from 'siema'

/**
 * Carousel
 *
 * @param {Element} where
 */
export const carousel = (where) => {
	const carousels = where.querySelectorAll('[data-carousel]')
	Array.from(carousels).forEach(carousel => init(carousel))
}

/**
 * @param {Element} element
 */
const init = element => {
	const carouselItems = element.querySelector('[data-carousel-items]')

	const optionsAttribute = element.getAttribute('data-carousel')
	let options = JSON.parse(optionsAttribute)

	const items = element.querySelectorAll('[data-carousel-item]')
	items.forEach((item, index) => {
		item.setAttribute('data-carousel-item', index)
	})

	const instance = new Siema(Object.assign(options, {
		selector: carouselItems,
		onInit() {
			element.classList.add('js-carousel')
			if (items) {
				setEdges(this, items)
			}
		},
		onChange() {
			if (items) {
				setEdges(this, items)
			}
		}
	}))
	document.querySelector('[data-carousel-prev]')
		.addEventListener('click', () => instance.prev())
	document.querySelector('[data-carousel-next]')
		.addEventListener('click', () => instance.next())
	window.addEventListener('resize', () => {
		setEdges(instance, items)
	}, { passive: true })
}

/**
 * @param {{
 *     perPage: int
 *     currentSlide: int
 * }} instance
 * @param {NodeList} items
 */
const setEdges = (instance, items) => {
	const { perPage, currentSlide } = instance
	edges({ perPage, currentSlide, items })
}

/**
 *
 * @param {int}, perPage
 * @param {int} currentSlide
 * @param {NodeList} items
 */
const edges = ({ perPage, currentSlide, items }) => {
	const itemsArray = Array.from(items)
	items.forEach(item => {
		item.removeAttribute('data-carousel-edge')
	})
	if (perPage < 3) return
	const edgeItems = {
		start: currentSlide,
		// FIXME incorrect for loop duplicates
		end: currentSlide + perPage - 1
	}
	const startItem = itemsArray[edgeItems.start]
	const endItem = itemsArray[edgeItems.end]
	startItem.setAttribute('data-carousel-edge', 'start')
	endItem.setAttribute('data-carousel-edge', 'end')
}
